<template>
  <body-simple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        نتیجه پرداخت تبلیغ هدفمند
      </h2>
      <p class="mt-3">اکنون می‌توانید با زدن دکمه تکمیل سفارش سفارش خود را تکمیل کنید</p>
    </template>
    <template #default>
      <div
        class="
          w-full
          md:w-8/12
          lg:w-6/12
          xl:w-5/12
          mx-auto
          bg-blueGray-50
          border border-coolGray-200
          rounded-md
          overflow-hidden
        "
      >
        <h2
          class="text-lg font-bold text-center p-3 bg-green-500 text-green-50"
          v-if="pay.status >= 100"
        >
          سفارش : {{ pay.id }}
        </h2>
        <h2 class="text-lg font-bold text-center p-3 bg-red-500 text-red-50" v-else>
          سفارش : {{ pay.id }}
        </h2>
        <div>
          <div class="p-2 font-bold text-center odd:bg-blueGray-100">
            {{ pay.description }}
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">موضوع</div>
            <div class="font-bold text-left">{{ ad.type?.name ?? "سایر موارد" }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">تعداد ارسال</div>
            <div class="font-bold text-left">{{ ad.sends?.length }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">تعداد گروه</div>
            <div class="font-bold text-left">
              {{ Object.values(L_.groupBy(ad.sends, "group_id")).length }}
            </div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">کلید زیر بنر</div>
            <div class="font-bold text-left">{{ ad.sub_keys?.length ?? 0 }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">شروع</div>
            <div class="font-bold text-left">{{ ad.start_at?.fa }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">پایان</div>
            <div class="font-bold text-left">{{ ad.end_at?.fa }}</div>
          </div>
          <div class="p-2 text-center font-bold text-x">
            {{ $number.format(pay.amount) }} تومان
          </div>
          <div
            class="text-lg font-bold text-center p-3 bg-green-500 text-green-50"
            v-if="pay.status >= 100"
          >
            پرداخت موفق
          </div>
          <div class="text-lg font-bold text-center p-3 bg-red-500 text-red-50" v-else>
            پرداخت ناموفق
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="flex w-full">
        <div class="flex-1 mx-1">
          <Button
            :to="{
              name: 'TargetOrderEdit',
              params: { id: $store.state.user.id, adId: ad?.id ?? 0 },
            }"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="sky"
          >
            تکمیل سفارش
          </Button>
        </div>
        <div class="flex-1 mx-1">
          <Button
            :to="{ name: 'Ad', params: { id: $store.state.user.id } }"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="teal"
          >
            سفارش های من
          </Button>
        </div>
      </div>
    </template>
  </body-simple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";

export default {
  components: {
    Icon,
    Button,
    BodySimple,
  },
  data() {
    return {
      sends: [],
      ad: {},
      L_: _,
      firstLoading: true,
      disabaledForm: false,
      pay: {},
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.getAd();
        // console.log($this.$route.params.payId);
      })
      .catch();
  },
  methods: {
    getAd() {
      let $this = this;
      $this.$axios
        .get("/api/ad-target-pays/" + $this.$route.params.payId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          $this.pay = response.data.pay;
          $this.ad = response.data.ad;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
          $this.disabaledForm = false;
        });
    },
  },
};
</script>
